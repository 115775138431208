@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");

body {
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    line-height: unset;
}

section {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

section.first {
    flex: 0 0 100%;
    height: 600px;
    background-image: url("../images/section-1-bg.jpg");
}

section.first .content__left-text {
    margin-bottom: 41px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #fff;
}

section .container {
    flex: 0 0 900px;
    padding: 81px 150px;
    align-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

section .container .header {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

section .content {
    display: flex;
    padding-top: 53px;
    align-content: center;
    justify-content: flex-start;
}

section .content__left {
    flex: 0 0 460px;
}

section .content__right {
    position: relative;
    -webkit-box-flex: 0;
    flex: 0 0 440px;
}

section.second {
    flex: 0 0 100%;
    height: 1000px;
    background-color: #fff;
}

section.second .content {
    padding-top: 7px;
    padding-bottom: 71px;
    border-bottom: 2px solid #e1e1e1;
}

section.second .content .cta__btn {
    margin-top: 12px;
    width: 255px;
    height: 50px;
}

section.second .content .cta__btn:hover {
    background-color: #fff;
    color: #ff881e;
    border-color: #ff881e;
}

section.second .content__left {
    flex: 0 0 530px;
}

section.second .content__left-text {
    margin-top: 20px;
    width: 300px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #707070;
    line-height: 23px;
}

section.second .content__right {
    flex: 0 0 370px;
}

section.second .content__right-text {
    margin-top: 20px;
    width: 300px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #707070;
    line-height: 23px;
}

section.second .content:nth-child(2) {
    padding-top: 110px;
    border-bottom: none;
}

section.second .content:nth-child(2) .content__left {
    flex: 0 0 370px;
}

section.second .content:nth-child(2) .content__left *:not(.cta__btn-wrapper) {
    margin-left: 55px;
}

section.second .content:nth-child(2) .content__right {
    flex: 0 0 530px;
}

section.third {
    flex: 0 0 100%;
    height: 507px;
    background-image: url("../images/section-3-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

section.third .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

section.third .content__text {
    margin-bottom: 41px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #fff;
    text-align: center;
}

section.third .h1 {
    margin-bottom: 11px;
    flex: 0 0 100%;
    text-align: center;
    font-size: 34px;
}

.container__dot {
    width: 76px !important;
    bottom: 69px !important;
    left: 50%;
    margin-left: -38px !important;
}

.cta__btn {
    width: 350px;
    height: 70px;
    color: #fff;
    background-color: #ff881e;
    border: 5px solid transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 700;
    transition: 0.3s;
}

.cta__btn:hover {
    cursor: pointer;
    border: 5px solid #fff;
}

.cta__btn-wrapper {
    text-decoration: none;
}

.cta__btn-wrapper:any-link {
    text-decoration: none;
}

.cta__btn-wrapper:hover {
    cursor: pointer;
}

.cta__disclaimer {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #fff;
}

.cws {
    margin-left: auto;
}

.divider__multicolor {
    flex: 0 0 100%;
    width: 100%;
    height: 15px;
    background: #0cba0a;
    background: linear-gradient(90deg, #0cba0a 0%, #0cba0a 25%, #f0f00a 25%, #f0f00a 50%, #eb7c09 50%, #eb7c09 75%, #d9330a 75%, #d9330a 100%);
}

.divider__multicolor-second {
    flex: 0 0 100%;
    width: 100%;
    height: 15px;
    background: #1c49d9;
    background: linear-gradient(90deg, #1c49d9 0%, #1c49d9 25%, #952aeb 25%, #952aeb 50%, #eb23be 50%, #eb23be 75%, #000000 75%, #000000 100%);
}

.footer div.footer {
    margin-top: 0;
    color: #707070 !important;
}

.h1 {
    margin: 0 0 29px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 34px;
    font-weight: 700;
    color: #fff;
    line-height: 46px;
    text-shadow: 2px 2px 8px #000;
}

.h2 {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #20324f;
    line-height: 32px;
}

.laptop {
    margin-top: 20px;
}

.notifications {
    margin-top: 8px;
    margin-left: 112px;
}

.slide-in-bottom {
    -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.widget {
    margin-top: 30px;
    margin-left: 30px;
}

.widget-1 {
    width: 100%;
}

.widget__container {
    height: 187px;
    width: 105px;
    position: absolute;
    top: 60px;
    right: 65px;
    overflow: hidden;
    -webkit-animation: slide-down 10s ease-in 2s infinite both;
    animation: slide-down 10s ease-in 2s infinite both;
}

@-webkit-keyframes slide-down {
    0% {
        height: 0%;
    }

    5% {
        height: 100%;
    }

    60% {
        height: 100%;
    }

    70% {
        height: 0%;
    }

    100% {
        height: 0%;
    }
}

@keyframes slide-down {
    0% {
        height: 0%;
    }

    5% {
        height: 100%;
    }

    60% {
        height: 100%;
    }

    70% {
        height: 0%;
    }

    100% {
        height: 0%;
    }
}

@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/* almost done drop down start*/

.almost-done-icon {
    margin-top: 10px;
    width: 75px !important;
    ;
    position: absolute;
    left: 50%;
    margin-left: -37.5px !important;
}

.container__dot {
    width: 76px !important;
    bottom: 84px !important;
    left: 50%;
    margin-left: -38px !important;
}

div.disclaimer {
    font-size: 12px;
}

.next {
    text-align: center;
    margin-top: 140px !important;
    font-size: 20px;
    line-height: 24px;
    color: #000;
}